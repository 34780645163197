import React, { useState, useRef } from "react";
import {
  Container,
  Grid,
  Typography,
  Box,
  Button,
  useTheme,
  useMediaQuery,
  Link,
  Modal,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import Carousel from "react-material-ui-carousel";
import { FormattedMessage, useIntl } from "react-intl";
import PricingModal from "bundles/LandingPageRecruiter/components/PricingModal";
import PricingDetails from "./PricingDetails";
import AvailableJobs from "./AvailableJobs";
import NewContactForm from "bundles/common/components/NewContactForm";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { useSnackbar } from "notistack";
import LandingBot from "./LandingBot";
import Banner from "bundles/common/components/Banner";
import sh from "bundles/common/utils/sh";
import NewCandidateCard from "bundles/Recruiter/components/NewCandidateCard";
import CompanyCard from "bundles/Recruiter/components/CompanyCard";
import Jules from "pictures/picture-jules.jpg";
import JulesSmall from "pictures/PictureJulesSmall.jpg";
import Jade from "pictures/picture-jade.jpg";
import JadeSmall from "pictures/PictureJadeSmall.jpg";
import Laptop from "pictures/recruiter-landing-laptop.png";
import LaptopSmall from "pictures/CompanyPageWebsiteSmall.png";
import Office from "icons/office_icon.svg";
import Bag from "icons/working_bag_icon.svg";
import Person from "icons/person_looking_icon.svg";
import Google from "pictures/logo-google-ads.png";
import GoogleSmall from "pictures/LogoGoogleAdsSmall.png";
import Facebook from "pictures/logo-meta.png";
import LinkedIn from "pictures/logo-linkedin.png";
import CarouselItem from "./CarouselItem";
import Gioia from "pictures/gioia-igretec.jpeg";
import GioiaSmall from "pictures/GioiaIgretecSmall.jpeg";
import Femme from "pictures/square-femme.jpg";
import Partner_1 from "pictures/biowin-le-forum-member.jpeg";
import Partner_1Small from "pictures/BiowinLeForumMemberSmall.jpeg";
import Partner_2 from "pictures/jobfair-engineers.png";
import Partner_2Small from "pictures/JobFairEngineersSmall.png";
import Partner_3 from "pictures/logo-yeptech-dark.png";
import Partner_3Small from "pictures/LogoYeptechDarkSmall.png";
import Partner_4 from "pictures/umons-rouge-quadri.png";
import Partner_4Small from "pictures/UmonsRougeQuadriSmall.png";
import Partner_5 from "pictures/universiteit-antwerpen-nieuw-logo.png";
import Partner_5Small from "pictures/UniversiteitAntwerpenNieuwLogoSmall.png";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import PhoneIcon from "@mui/icons-material/Phone";
import DateRangeIcon from "@mui/icons-material/DateRange";
import CraneRecruiter from "icons/crane-recruiter2.svg";
import Mock_1 from "pictures/mock_1.jpeg";
import Mock_2 from "pictures/mock_2.jpeg";
import Mock_2Small from "pictures/MockWomanSmall.jpeg";
import Mock_3 from "pictures/mock_3.jpeg";
import Mock_3Small from "pictures/MockArchitectSmall.png";
import SignupModal from "./SignupModal";
import DemoBanner from "./DemoBanner";

const processess = [
  { number: 1, buttonLabelId: "landing_recruiter.process.step_1" },
  { number: 2, buttonLabelId: "landing_recruiter.process.step_2" },
  { number: 3, buttonLabelId: "landing_recruiter.process.step_3" },
  { number: 4, buttonLabelId: "landing_recruiter.process.step_4" },
];

const LandingPageRecruiter = () => {
  const i18nLocale = useSelector((state) => state.i18nLocale);
  const authenticityToken = useSelector((state) => state.authenticity_token);
  const theme = useTheme();
  const intl = useIntl();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const { enqueueSnackbar } = useSnackbar();

  const [isSignupModalOpened, setIsSignupModalOpened] = useState(false);
  const [signUpModalContext, setSignUpModalContext] = useState(null);
  const [candidatesCount, setCandidatesCount] = useState(0);
  const [candidatesSinceMonthCount, setCandidatesSinceMonthCount] = useState(0);
  const [jobApplicationsCount, setJobApplicationsCount] = useState(0);
  const [
    spontaneousApplicationsYearCount,
    setSpontaneousApplicationsYearCount,
  ] = useState(0);
  const [companiesCount, setCompaniesCount] = useState(0);
  const [trustedCompanies, setTrustedCompanies] = useState([]);
  const [activeButtonIndex, setActiveButtonIndex] = useState(0);
  const [currentProcess, setCurrentProcess] = useState(
    processess.find((process) => process.number === 1)
  );

  const handleButtonClick = (index) => {
    const selectedProcess = processess[index];
    setCurrentProcess(selectedProcess);
    setActiveButtonIndex(index);
  };

  const testimonials = [
    {
      key: 1,
      text: "landing_recruiter.testimonial.gioia_text",
      name: "landing_recruiter.testimonial.gioia_name",
      position: "landing_recruiter.testimonial.gioia_position",
      companyUrl: `https://squarehub.eu/${i18nLocale}/candidate/company/132?redirectTo=recruiters`,
      picture: Gioia,
      picturesmall: GioiaSmall,
      alt: "Gioia Parrinello",
    },
    {
      key: 2,
      text: "landing_recruiter.testimonial.isabel_text",
      name: "landing_recruiter.testimonial.isabel_name",
      position: "landing_recruiter.testimonial.isabel_position",
      companyUrl: `https://squarehub.eu/${i18nLocale}/candidate/company/118?redirectTo=recruiters`,
      picture: Femme,
      picturesmall: Femme,
      alt: "Isabel Vanlerberghe",
    },
    {
      key: 3,
      text: "landing_recruiter.testimonial.marilyn_text",
      name: "landing_recruiter.testimonial.marilyn_name",
      position: "landing_recruiter.testimonial.marilyn_position",
      companyUrl: `https://squarehub.eu/${i18nLocale}/candidate/company/481?redirectTo=recruiters`,
      picture: Femme,
      picturesmall: Femme,
      alt: "Marilyn M.",
    },
  ];

  const mockCandidateProfiles = [
    {
      first_name: "Fred",
      last_name: "Mertens",
      job_title: intl.formatMessage({
        id: "landing_recruiter.candidate_profiles.engineer",
      }),
      region: "hainaut",
      profile_picture_url: Mock_1,
      small_profile_picture_url: Mock_1,
      availability: "looking",
      candidate_status: "employee",
      favorite: false,
    },
    {
      first_name: "Sandra",
      last_name: "Jacobs",
      job_title: intl.formatMessage({
        id: "landing_recruiter.candidate_profiles.architect",
      }),
      region: "brussels",
      profile_picture_url: Mock_2,
      small_profile_picture_url: Mock_2Small,
      availability: "looking",
      candidate_status: "employee",
      favorite: true,
    },
    {
      first_name: "Michael",
      last_name: "Dubois",
      job_title: intl.formatMessage({
        id: "landing_recruiter.candidate_profiles.hvac",
      }),
      region: "liege",
      profile_picture_url: Mock_3,
      small_profile_picture_url: Mock_3Small,
      availability: "listening",
      candidate_status: "independent",
      favorite: false,
    },
  ];

  const useStyles = makeStyles((theme) => ({
    root: {
      maxWidth: 345,
    },
    container: {
      backgroundImage: isDesktop ? `url(${CraneRecruiter})` : "unset",
      backgroundPosition: isDesktop ? "75%" : "unset",
      backgroundRepeat: isDesktop ? "no-repeat" : "unset",
      backgroundSize: isDesktop ? "inherit" : "unset",
      paddingBottom: isDesktop ? "64px" : "32px",
      flexGrow: 99,
      backgroundColor: theme.palette.common.white,
      height: isDesktop ? "calc(100vh - 120px)" : "auto",
      display: "flex",
      alignItems: "center",
    },
    recruting: {
      paddingTop: isDesktop ? "32px" : "0px",
      paddingBottom: isDesktop ? "64px" : "72px",
    },
    process: {
      backgroundColor: theme.palette.common.white,
      paddingTop: isDesktop ? "32px" : "0px",
      paddingBottom: isDesktop ? "52px" : "0px",
      marginLeft: isDesktop ? "100px" : "0px",
      marginRight: isDesktop ? "100px" : "0px",
    },
    ourProfiles: {
      paddingTop: isDesktop ? "32px" : "120px",
      paddingBottom: isDesktop ? "62px" : "32px",
      backgroundColor: isDesktop ? "unset" : theme.palette.common.white,
    },
    fields: {
      backgroundColor: theme.palette.common.white,
      paddingTop: isDesktop ? "32px" : "0px",
      paddingBottom: isDesktop ? "82px" : "62px",
    },
    trust: {
      color: theme.palette.text.white,
      paddingTop: isDesktop ? "32px" : "32px",
      paddingBottom: isDesktop ? "32px" : "32px",
    },
    account: {
      backgroundColor: theme.palette.common.white,
      paddingTop: isDesktop ? "32px" : "32px",
      paddingBottom: isDesktop ? "32px" : "32px",
    },
    pricing: {
      paddingTop: isDesktop ? "96px" : "62px",
      paddingBottom: isDesktop ? "100px" : "82px",
    },
    download: {
      paddingTop: isDesktop ? "0px" : "42px",
      paddingBottom: isDesktop ? "32px" : "62px",
    },
    carousel: {
      paddingTop: isDesktop ? "32px" : "0px",
      paddingBottom: isDesktop ? "80px" : "82px",
      backgroundColor: theme.palette.common.white,
    },
    partners: {
      paddingTop: isDesktop ? "62px" : "32px",
      paddingBottom: isDesktop ? "32px" : "0px",
      display: "flex",
      justifyContent: "center",
    },
    contactForm: {
      backgroundImage:
        "radial-gradient(ellipse at 50% 50%, #3F4C71 0.5%, #3F4C71 15%, #2A334E 50%, #2A334E 100%)",
      dropShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
      paddingTop: isDesktop ? "64px" : "32px",
      paddingBottom: isDesktop ? "64px" : "32px",
    },
    blueTheme: {
      backgroundColor: theme.palette.text.blue,
      color: "white",
      paddingLeft: "0 !important",
      paddingRight: "0 !important",
    },
    marketingBox: {
      borderRadius: "4px",
      padding: "32px 16px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: `${theme.palette.secondary.main} !important`,
      color: `${theme.palette.common.white} !important`,
    },
    overlay: {
      position: "absolute",
      top: "0",
      left: "0",
      width: "370px",
      height: "370px",
      borderRadius: "50px",
      backgroundColor: "rgba(255, 255, 255, 0.35)",
      zIndex: "1",
    },
    header: {
      wordBreak: "normal",
      fontFamily: "Montserrat",
      fontSize: "48px",
      fontWeight: "600",
    },
    ellipseStyle: {
      borderRadius: "50%",
      width: "140px",
      height: "140px",
      objectFit: "cover",
      border: "2px solid white",
    },
  }));

  const classes = useStyles();
  const [showModal, setShowModal] = useState(false);

  const fetchDataCount = () => {
    sh.get("landing_recruiter_data", { authenticity_token: authenticityToken })
      .then((res) => {
        setCandidatesCount(res.data.candidates_count);
        setCandidatesSinceMonthCount(res.data.candidates_since_month_count);
        setJobApplicationsCount(res.data.job_applications_count);
        setSpontaneousApplicationsYearCount(
          res.data.spontaneous_applications_year_count
        );
        setCompaniesCount(res.data.companies_count);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const trustedCompanyIds = JSON.parse(process.env.TRUSTED_COMPANY_IDS) || [];

  const onTrustUsClicked = () => {
    window.location.href = `/${i18nLocale}/recruiters/companies`;
  };

  const fetchTrustedCompanies = () => {
    sh.get("search/companies", {
      params: {
        authenticity_token: authenticityToken,
        filters: { id: trustedCompanyIds },
      },
    })
      .then((res) => {
        const companies = res.data.data.companies.slice(0, 4);
        setTrustedCompanies(companies);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    fetchDataCount();
    fetchTrustedCompanies();
    if (new URLSearchParams(window.location.search).get("snackbarMessage") === "TokenInvalid") {
      enqueueSnackbar(
        intl.formatMessage({ id: "settings.invitations.invalid_token" }),
        {
          variant: "error",
        }
      );
    }
  }, []);

  useEffect(() => {}, [isDesktop]);

  const [showDemoBanner, setDemoBanner] = useState(false);
  const targetRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      const targetPosition = targetRef.current
        ? targetRef.current.getBoundingClientRect().top + window.scrollY
        : 1800;

      if (window.scrollY > targetPosition && !showDemoBanner) {
        setDemoBanner(true);
      }
      if (window.scrollY <= targetPosition && showDemoBanner) {
        setDemoBanner(false);
      }
    };

    window.addEventListener("scroll", handleScroll, { passive: false });
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [showDemoBanner, targetRef]);

  return (
    <>
      <Box>
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Banner
            loading={false}
            actionCallback={() => {}}
            message={
              <Typography
                align="center"
                color={theme.palette.common.white}
                variant="body1"
                style={{
                  margin: isDesktop ? "unset" : "20px",
                  marginBottom: "unset",
                  marginTop: "unset",
                }}
              >
                <FormattedMessage id="landing_recruiter.contact_us" />
              </Typography>
            }
            actionMessage={
              <Typography
                variant="body1bold"
                color="white"
                style={{
                  color: "white",
                  textDecoration: "underline",
                }}
              >
                <Link href="tel:+3223291100" color="#FFFFFF" size="small">
                  +32 (0) 2 329 11 00
                </Link>
              </Typography>
            }
            message2={
              <Typography
                align="center"
                color={theme.palette.common.white}
                variant="body1"
              >
                <FormattedMessage id="landing_recruiter.or" />
              </Typography>
            }
            actionMessage2={
              <Typography
                variant="body1bold"
                color="white"
                style={{
                  color: "white",
                  textDecoration: "underline",
                }}
              >
                <Link href="https://calendly.com/demo-squarehub/quick-demo-30-min" color="#FFFFFF" size="small">
                  <FormattedMessage id="banner_demo" />
                </Link>
              </Typography>
            }
          />
          <Box className={classes.container}>
            <Container maxWidth="lg">
              <Grid container spacing={4}>
                <Grid
                  item
                  xs={12}
                  md={6}
                  style={{
                    marginTop: isDesktop ? "64px" : "32px",
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography
                        variant="h3"
                        color="secondary"
                        style={{ fontFamily: "Work Sans Bold" }}
                        gutterBottom
                      >
                        <FormattedMessage
                          id="landing_recruiter.welcome_html"
                          values={{
                            b: (chunks) => <b>{chunks}</b>,
                          }}
                        />
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography
                        variant="h1"
                        className={classes.header}
                        style={{
                          color: theme.palette.common.blue,
                          fontWeight: 600,
                          fontFamily: "Work Sans Bold",
                        }}
                      >
                        <FormattedMessage id="landing_recruiter.baseline_html" />
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <ul style={{ paddingLeft: "unset" }}>
                        <Typography variant="subtitle">
                          <li style={{ display: "flex", columnGap: "4px" }}>
                            <Box
                              variant="body2"
                              style={{
                                marginRight: "8px",
                                color: theme.palette.primary.main,
                              }}
                            >
                              ✓
                            </Box>
                            <b
                              style={{
                                fontWeight: 600,
                                fontFamily: "Work Sans Bold",
                              }}
                            >
                              {candidatesCount}
                            </b>
                            <FormattedMessage
                              id="landing_recruiter.li_1"
                              values={{
                                b: (chunks) => <b>{chunks}</b>,
                              }}
                              style={{ color: theme.palette.secondary.main }}
                            />
                          </li>
                        </Typography>
                        <Typography variant="subtitle">
                          <li style={{ display: "flex", columnGap: "4px" }}>
                            <Box
                              variant="body2"
                              style={{
                                marginRight: "8px",
                                color: theme.palette.primary.main,
                              }}
                            >
                              ✓
                            </Box>
                            <b
                              style={{
                                fontWeight: 600,
                                fontFamily: "Work Sans Bold",
                              }}
                            >
                              {jobApplicationsCount}
                            </b>
                            <FormattedMessage
                              id="landing_recruiter.li_2"
                              values={{
                                b: (chunks) => <b>{chunks}</b>,
                              }}
                              style={{ color: theme.palette.secondary.main }}
                            />
                          </li>
                        </Typography>
                        <Typography variant="subtitle">
                          <li style={{ display: "flex", columnGap: "4px" }}>
                            <Box
                              variant="body2"
                              style={{
                                marginRight: "8px",
                                color: theme.palette.primary.main,
                              }}
                            >
                              ✓
                            </Box>
                            <b
                              style={{
                                fontWeight: 600,
                                fontFamily: "Work Sans Bold",
                              }}
                            >
                              {companiesCount}
                            </b>
                            <FormattedMessage
                              id="landing_recruiter.li_3"
                              values={{
                                b: (chunks) => <b>{chunks}</b>,
                              }}
                              style={{ color: theme.palette.secondary.main }}
                            />
                          </li>
                        </Typography>
                      </ul>
                    </Grid>
                    <Box
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginLeft: isDesktop ? "16px" : "auto",
                        marginRight: isDesktop ? "0px" : "auto",
                        paddingLeft: isDesktop ? "0px" : "16px",
                      }}
                    >
                      <Box
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          flexDirection: isDesktop ? "row" : "column",
                        }}
                      >
                        <Button
                          variant="rounded"
                          color="primaryContained"
                          href={`/${i18nLocale}/recruiters/sign_up`}
                          style={{
                            marginTop: "16px",
                            marginBottom: "16px",
                            marginRight: "10px",
                            width: isDesktop ? "250px" : "100%",
                          }}
                        >
                          <FormattedMessage id="landing_recruiter.create_account" />
                        </Button>
                        <Button
                          variant="rounded"
                          color="primaryOutlined"
                          href={`/${i18nLocale}`}
                          style={{
                            marginTop: isDesktop ? "16px" : "0px",
                            marginBottom: "16px",
                            width: isDesktop ? "250px" : "100%",
                          }}
                        >
                          <FormattedMessage id="devise.signup.iam_candidate" />
                        </Button>
                      </Box>
                      <Box
                        style={{
                          width: "250px",
                          display: "flex",
                          fontSize: "12px",
                          justifyContent: "center",
                        }}
                      >
                        <Typography variant="text">
                          <FormattedMessage id="landing_recruiter.button_message" />
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box
                    style={{
                      height: isDesktop ? "560px" : "50vh",
                      width: isDesktop ? "536px" : "100%",
                      paddingTop: isDesktop ? "64px" : "0px",
                      paddingBottom: isDesktop ? "64px" : "0px",
                      paddingLeft: isDesktop ? "48px" : "0px",
                    }}
                  >
                    <LandingBot />
                  </Box>
                </Grid>
              </Grid>
            </Container>
          </Box>
        </Box>
        <Box className={classes.recruting}>
          <Container maxWidth="lg">
            <Grid container>
              <Grid item xs={12}>
                <Typography
                  variant="h1"
                  style={{
                    textAlign: "center",
                    marginTop: "40px",
                    marginBottom: "40px",
                    fontWeight: "bold",
                    fontSize: isDesktop ? "36px" : "34px",
                  }}
                >
                  <FormattedMessage id="landing_recruiter.recruting.header" />
                </Typography>
                <Typography
                  variant="h5"
                  style={{
                    textAlign: "center",
                    marginBottom: "50px",
                    fontSize: "18px",
                  }}
                >
                  <FormattedMessage id="landing_recruiter.recruting.sub_header" />
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Box
                  style={{
                    marginTop: isDesktop ? "30px" : "0px",
                    display: "flex",
                    flexDirection: isDesktop ? "row" : "column",
                    columnGap: isDesktop ? "30px" : "unset",
                    rowGap: isDesktop ? "unset" : "40px",
                  }}
                >
                  <Box
                    style={{
                      flex: "1 1 150px",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      alignItems: "center",
                      rowGap: "20px",
                      height: "300px",
                      width: isDesktop ? "330px" : "100%",
                    }}
                  >
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        flexDirection: "column",
                        alignItems: "center",
                        rowGap: "20px",
                      }}
                    >
                      <img src={Office} alt="Bag" width="40px" />
                      <Typography
                        variant="h5"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          textAlign: "center",
                          fontSize: "18px",
                          fontWeight: "600",
                        }}
                      >
                        <FormattedMessage id="landing_recruiter.recruting.step_1_header" />
                      </Typography>
                      <Typography
                        variant="h7"
                        style={{
                          textAlign: "center",
                          fontSize: "14px",
                        }}
                      >
                        <FormattedMessage id="landing_recruiter.recruting.step_1_text" />
                      </Typography>
                    </Box>
                    <Box>
                      <Button
                        color="primaryOutlined"
                        variant="rounded"
                        size="medium"
                        onClick={() => {
                          setIsSignupModalOpened(true);
                          setSignUpModalContext("companyPage");
                        }}
                        style={{
                          margin: "auto",
                          width: "250px",
                        }}
                      >
                        <FormattedMessage id="landing_recruiter.recruting.button_1" />
                      </Button>
                    </Box>
                  </Box>
                  <Box
                    style={{
                      flex: "1 1 150px",
                      display: "flex",
                      justifyContent: "space-between",
                      flexDirection: "column",
                      alignItems: "center",
                      rowGap: "20px",
                      height: "300px",
                      width: isDesktop ? "330px" : "100%",
                    }}
                  >
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        flexDirection: "column",
                        alignItems: "center",
                        rowGap: "20px",
                      }}
                    >
                      <img src={Bag} alt="Bag" width="40px" />
                      <Typography
                        variant="h5"
                        style={{
                          textAlign: "center",
                          fontSize: "18px",
                          fontWeight: "600",
                        }}
                      >
                        <FormattedMessage id="landing_recruiter.recruting.step_2_header" />{" "}
                      </Typography>
                      <Typography
                        variant="h7"
                        style={{
                          marginBottom: "20px",
                          textAlign: "center",
                          fontSize: "14px",
                        }}
                      >
                        <FormattedMessage id="landing_recruiter.recruting.step_2_text" />
                      </Typography>
                    </Box>
                    <Box>
                      <Button
                        color="primaryOutlined"
                        variant="rounded"
                        size="medium"
                        onClick={() => {
                          setIsSignupModalOpened(true);
                          setSignUpModalContext("jobOfferCreation");
                        }}
                        style={{
                          margin: "auto",
                          width: "250px",
                        }}
                      >
                        <FormattedMessage id="landing_recruiter.recruting.button_2" />
                      </Button>
                    </Box>
                  </Box>
                  <Box
                    style={{
                      flex: "1 1 150px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "space-between",
                      rowGap: "20px",
                      height: "300px",
                      width: isDesktop ? "330px" : "100%",
                    }}
                  >
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        flexDirection: "column",
                        alignItems: "center",
                        rowGap: "20px",
                      }}
                    >
                      <img
                        src={Person}
                        alt="Person"
                        width="40px"
                        height="38px"
                      />
                      <Typography
                        variant="h5"
                        style={{
                          textAlign: "center",
                          fontSize: "18px",
                          fontWeight: "600",
                        }}
                      >
                        <FormattedMessage id="landing_recruiter.recruting.step_3_header" />
                      </Typography>
                      <Typography
                        variant="h7"
                        style={{
                          marginBottom: "20px",
                          textAlign: "center",
                          fontSize: "14px",
                        }}
                      >
                        <FormattedMessage id="landing_recruiter.recruting.step_3_text" />
                      </Typography>
                    </Box>
                    <Box>
                      <Button
                        color="primaryOutlined"
                        variant="rounded"
                        size="medium"
                        onClick={() => {
                          setIsSignupModalOpened(true);
                          setSignUpModalContext("cvAccess");
                        }}
                        style={{
                          width: "250px",
                          margin: "auto",
                        }}
                      >
                        <FormattedMessage id="landing_recruiter.recruting.button_3" />
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Box>
        <Box className={classes.process}>
          {isDesktop && (
            <Container>
              <Grid container>
                <Grid item xs={12}>
                  <Typography
                    variant="h2"
                    style={{ padding: "30px", fontWeight: "bold" }}
                  >
                    <FormattedMessage id="landing_recruiter.process.header" />
                  </Typography>
                </Grid>
                <Grid item xs={12} container justify="space-between">
                  <Box display="flex" flexDirection="row">
                    <Box
                      xs={6}
                      md={6}
                      display="flex"
                      flexDirection="column"
                      height={isDesktop ? "285px" : "unset"}
                      style={{ padding: "20px", rowGap: "20px" }}
                    >
                      {processess.map((item, index) => (
                        <Button
                          key={index}
                          color={
                            activeButtonIndex === index
                              ? "secondaryContained"
                              : "secondaryOutlined"
                          }
                          variant="lessRounded"
                          style={{
                            margin: "auto",
                            width: "220px",
                            height: "45px",
                            boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
                          }}
                          onClick={() => handleButtonClick(index)}
                        >
                          <FormattedMessage id={item.buttonLabelId} />
                        </Button>
                      ))}
                    </Box>
                    {currentProcess.number === 1 && (
                      <Box display="flex" flexDirection="column" style={{ padding: "15px", paddingBottom: "0px", paddingRight: "30px" }}>
                        <Box flex={1} style={{ minHeight: "50px" }}>
                          <Typography variant="h4" style={{ marginBottom: "20px", fontWeight: "600" }}>
                            <FormattedMessage id="landing_recruiter.process.sub_header_1" />
                          </Typography>
                        </Box>
                        <Box flex={1} style={{ minHeight: "140px" }}>
                          <Typography variant="h5" style={{ marginBottom: "30px", lineHeight: "30px", marginRight: "20px" }}>
                            <FormattedMessage id="landing_recruiter.process.text_1" values={{ b: (chunks) => <b>{chunks}</b> }} />
                          </Typography>
                        </Box>
                        <Box flex={1} style={{ minHeight: "70px" }}>
                          <Box display="flex" flexDirection="row">
                            <Typography
                              variant="h4"
                              color="primary"
                              style={{
                                fontSize: "32px",
                                fontWeight: "700",
                                paddingTop: "15px",
                              }}
                            >
                            +{candidatesSinceMonthCount}
                            </Typography>
                            <Typography
                              variant="h5"
                              color="primary"
                              style={{
                                fontWeight: "bold",
                                marginLeft: "10px",
                                paddingTop: "26px",
                              }}
                            >
                              <FormattedMessage id="landing_recruiter.process.step_1_footer" />
                            </Typography>
                            <Box
                              style={{
                                marginLeft: "80px",
                                marginTop: "10px",
                              }}
                            >
                              <img
                                srcSet={`${Google} 1x, ${GoogleSmall} 0.5x`}
                                alt="Google"
                                style={{
                                  width: "150px",
                                  height: "55px",
                                  marginRight: "25px",
                                }}
                              />
                              <img
                                src={LinkedIn}
                                alt="LinkedIn"
                                style={{
                                  width: "90px",
                                  height: "55px",
                                  marginRight: "40px",
                                }}
                              />
                              <img
                                src={Facebook}
                                alt="Meta"
                                style={{
                                  width: "90px",
                                  height: "55px",
                                }}
                              />
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    )}
                    {currentProcess.number === 2 && (
                      <Box
                        style={{
                          padding: "20px",
                          display: "flex",
                          justifyContent: "space-between",
                          flexDirection: "column",
                        }}
                      >
                        <Box>
                          <Typography
                            variant="h4"
                            style={{ marginBottom: "20px", fontWeight: "600" }}
                          >
                            <FormattedMessage id="landing_recruiter.process.sub_header_2" />
                          </Typography>
                          <Typography
                            variant="h5"
                            style={{ marginBottom: "30px", lineHeight: "30px" }}
                          >
                            <FormattedMessage
                              id="landing_recruiter.process.text_2"
                              values={{
                                b: (chunks) => <b>{chunks}</b>,
                              }}
                            />
                          </Typography>
                        </Box>
                        <Box display="flex" flexDirection="row">
                          <Typography
                            variant="h4"
                            color="primary"
                            style={{ fontSize: "32px", fontWeight: "700" }}
                          >
                            <FormattedMessage id="landing_recruiter.process.number_2" />
                          </Typography>
                          <Typography
                            variant="h5"
                            color="primary"
                            style={{
                              fontWeight: "bold",
                              marginLeft: "10px",
                              paddingTop: "12px",
                            }}
                          >
                            <FormattedMessage id="landing_recruiter.process.step_2_footer" />
                          </Typography>
                        </Box>
                      </Box>
                    )}
                    {currentProcess.number === 3 && (
                      <Box style={{ padding: "20px" }}>
                        <Typography
                          variant="h4"
                          style={{ marginBottom: "20px", fontWeight: "600" }}
                        >
                          <FormattedMessage id="landing_recruiter.process.sub_header_3" />
                        </Typography>
                        <Typography
                          variant="h5"
                          style={{ marginBottom: "30px", lineHeight: "30px" }}
                        >
                          <FormattedMessage id="landing_recruiter.process.text_3" />
                        </Typography>
                        <Box>
                          <Typography variant="h5">
                            <FormattedMessage
                              id="landing_recruiter.process.step_3_footer"
                              values={{
                                b: (chunks) => <b>{chunks}</b>,
                              }}
                            />
                          </Typography>
                          <Typography
                            variant="h5"
                            style={{ lineHeight: "30px" }}
                          >
                            <FormattedMessage
                              id="landing_recruiter.process.step_3_footer_2"
                              values={{
                                b: (chunks) => <b>{chunks}</b>,
                              }}
                            />
                          </Typography>
                        </Box>
                      </Box>
                    )}
                    {currentProcess.number === 4 && (
                      <Box style={{ padding: "20px" }}>
                        <Typography
                          variant="h4"
                          style={{ marginBottom: "20px", fontWeight: "600" }}
                        >
                          <FormattedMessage id="landing_recruiter.process.sub_header_4" />
                        </Typography>
                        <Typography
                          variant="h5"
                          style={{ marginBottom: "30px", lineHeight: "30px" }}
                        >
                          <FormattedMessage
                            id="landing_recruiter.process.text_4"
                            values={{
                              b: (chunks) => <b>{chunks}</b>,
                            }}
                          />
                        </Typography>
                        <Box>
                          <Typography
                            variant="h5"
                            color="primary"
                            style={{ fontWeight: "bold" }}
                          >
                            <FormattedMessage id="landing_recruiter.process.step_4_footer" />
                          </Typography>
                        </Box>
                      </Box>
                    )}
                  </Box>
                </Grid>
              </Grid>
            </Container>
          )}
        </Box>
        <Box className={classes.ourProfiles}>
          <Container maxWidth="lg">
            <Grid
              container
              style={{ alignItems: "top", paddingBottom: "50px" }}
            >
              <Grid item xs={12} md={12}>
                <Box
                  style={{
                    paddingBottom: isDesktop ? "15px" : "30px",
                    marginTop: isDesktop ? "0px" : "-60px",
                    textAlign: "center",
                  }}
                >
                  <Typography
                    variant="h1"
                    style={{
                      fontWeight: "600",
                      paddingTop: isDesktop ? "50px" : "0px",
                      paddingBottom: isDesktop ? "20px" : "0px",
                      fontSize: isDesktop ? "36px" : "34px",
                    }}
                    ref={targetRef}
                  >
                    <FormattedMessage id="landing_recruiter.candidate_profiles.our_profiles" />
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box style={{ paddingRight: isDesktop ? "32px" : "0px" }}>
                  <Typography
                    variant="h5"
                    style={{
                      marginTop: isDesktop ? "35px" : "8px",
                      marginBottom: "30px",
                      fontSize: isDesktop ? "18px" : "14px",
                      textAlign: isDesktop ? "unset" : "center",
                    }}
                  >
                    <FormattedMessage id="landing_recruiter.candidate_profiles.catch_phrase" />
                  </Typography>
                  <Typography
                    variant="h5"
                    style={{
                      marginTop: "8px",
                      marginBottom: "8px",
                      fontSize: isDesktop ? "18px" : "14px",
                      textAlign: isDesktop ? "unset" : "center",
                    }}
                  >
                    <FormattedMessage
                      id="landing_recruiter.candidate_profiles.come_see"
                      values={{
                        b: (chunks) => <b>{chunks}</b>,
                      }}
                    />
                  </Typography>
                  <Button
                    variant="rounded"
                    color="secondaryContained"
                    style={{
                      marginTop: "24px",
                      width: isDesktop ? "200px" : "100%",
                    }}
                    onClick={() => {
                      setIsSignupModalOpened(true);
                      setSignUpModalContext("cvAccess");
                    }}
                  >
                    <FormattedMessage id="landing_recruiter.candidate_profiles.button" />
                  </Button>
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box
                  style={{
                    padding: isDesktop ? "16px 0px 36px 32px" : "16px 4px",
                    display: "flex",
                    flexDirection: "column",
                    rowGap: isDesktop ? "16px" : "8px",
                  }}
                >
                  {mockCandidateProfiles.map((candidate, index) => {
                    return (
                      <Box key={`candi-${index}`}>
                        <NewCandidateCard
                          candidate={candidate}
                          onClickOnCandidate={() => {}}
                          jobApplication={null}
                          active={false}
                          favorite={candidate.favorite}
                        />
                      </Box>
                    );
                  })}
                </Box>
              </Grid>
            </Grid>
            <DemoBanner isVisible={showDemoBanner} />
          </Container>
        </Box>
        <Box className={classes.fields}>
          <AvailableJobs />
        </Box>
        <Box className={classes.trust}>
          {isDesktop ? (
            <Container
              maxWidth="lg"
              style={{ paddingTop: "30px", paddingBottom: "60px" }}
            >
              <Box style={{ display: "flex", justifyContent: "center" }}>
                <Button
                  variant="text"
                  href={`/${i18nLocale}/trusted_companies`}
                  style={{
                    cursor: "pointer",
                    padding: "4px 10px",
                    borderRadius: "10px",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                >
                  <Typography
                    variant="h1"
                    style={{
                      color: theme.palette.secondary.main,
                      display: isDesktop ? "flex" : "unset",
                      justifyContent: "center",
                      fontSize: isDesktop ? "36px" : "34px",
                      textAlign: isDesktop ? "unset" : "center",
                    }}
                  >
                    <b>
                      {companiesCount}{" "}
                      <FormattedMessage id="landing_recruiter.trust_us" />
                    </b>
                  </Typography>
                </Button>
              </Box>
              <Box
                mx="auto"
                style={{
                  justifyContent: "center",
                  width: isDesktop ? "60%" : "100%",
                }}
              >
                <Typography
                  variant="body2"
                  style={{
                    color: theme.palette.secondary.main,
                    display: "flex",
                    fontSize: isDesktop ? "16px" : "14px",
                    justifyContent: "center",
                    marginTop: isDesktop ? "20px" : "40px",
                    marginBottom: "40px",
                    textAlign: "center",
                  }}
                >
                  <FormattedMessage
                    id="landing_recruiter.working_with_us"
                    values={{
                      b: (chunks) => <b>{chunks}</b>,
                    }}
                  />
                </Typography>
              </Box>
              <Box style={{ marginTop: "32px" }}>
                <Grid container spacing={4} alignItems="stretch">
                  {trustedCompanies.map((company, index) => {
                    return (
                      <Grid
                        item
                        xs={12}
                        md={3}
                        key={`company-${index}`}
                        style={{ marginBottom: "30px" }}
                      >
                        <Box style={{ height: "100%" }}>
                          <CompanyCard
                            company={company}
                            onClick={() => {
                              window.location.href = `${window.location.origin}/${i18nLocale}/companies/${company?.encoded_name}/${company?.id}?redirectTo=recruiters`;
                            }}
                          />
                        </Box>
                      </Grid>
                    );
                  })}
                  <Grid
                    item
                    xs={12}
                    style={{ display: "flex", justifyContent: "center" }}
                  ></Grid>
                </Grid>
              </Box>
            </Container>
          ) : (
            <Container maxWidth="lg" style={{ paddingBottom: "60px" }}>
              <Box>
                <Typography
                  variant="h1"
                  style={{
                    color: theme.palette.secondary.main,
                    display: "flex",
                    justifyContent: "center",
                    paddingTop: "20px",
                    fontSize: isDesktop ? "36px" : "34px",
                    textAlign: isDesktop ? "unset" : "center",
                  }}
                >
                  <b>
                    {companiesCount}&nbsp;
                    <FormattedMessage
                      id="landing_recruiter.trust_us"
                      values={{
                        b: (chunks) => <b>{chunks}</b>,
                      }}
                    />
                  </b>
                </Typography>
              </Box>
              <Box
                mx="auto"
                style={{
                  justifyContent: "center",
                  width: isDesktop ? "60%" : "100%",
                }}
              >
                <Typography
                  variant="body2"
                  style={{
                    color: theme.palette.secondary.main,
                    display: "flex",
                    justifyContent: "center",
                    marginTop: isDesktop ? "20px" : "40px",
                    marginBottom: "40px",
                    textAlign: "center",
                  }}
                >
                  <FormattedMessage
                    id="landing_recruiter.working_with_us"
                    values={{
                      b: (chunks) => <b>{chunks}</b>,
                    }}
                  />
                </Typography>
              </Box>
              <Carousel autoPlay={false} animation="slide">
                {trustedCompanies.map((company, index) => {
                  return (
                    <CompanyCard
                      key={`company-${index}`}
                      company={company}
                      onClick={() => {
                        window.location.href = `${window.location.origin}/${i18nLocale}/companies/${company?.encoded_name}/${company?.id}?redirectTo=recruiters`;
                      }}
                    />
                  );
                })}
              </Carousel>
            </Container>
          )}
        </Box>
        <Box className={classes.account}>
          <Container maxWidth="lg">
            <Grid
              container
              spacing={2}
              style={{ padding: isDesktop ? "50px" : "0px" }}
            >
              <Grid item xs={0} md={6}>
                <Box>
                  <img
                    src={Laptop}
                    srcSet={`${Laptop} 1x, ${LaptopSmall} 0.5x`}
                    alt="Laptop"
                    style={{
                      maxWidth: "100%",
                      height: "auto",
                      paddingRight: "30px",
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box>
                  <Typography variant="h1">
                    <FormattedMessage
                      id="landing_recruiter.account.create_page"
                      values={{
                        b: (chunks) => <b>{chunks}</b>,
                      }}
                    />
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="body1" style={{ marginTop: "10px" }}>
                    <FormattedMessage id="landing_recruiter.account.create_page_description" />
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    variant="h4"
                    style={{ marginTop: isDesktop ? "10px" : "30px" }}
                  >
                    <FormattedMessage
                      id="landing_recruiter.account.advantages"
                      values={{
                        b: (chunks) => <b>{chunks}</b>,
                      }}
                    />
                  </Typography>
                </Box>
                <ol style={{ paddingLeft: "unset" }}>
                  <Typography variant="body1">
                    <li
                      style={{
                        display: "flex",
                        columnGap: isDesktop ? "4px" : "unset",
                      }}
                    >
                      <Box
                        variant="body1"
                        style={{
                          marginRight: "8px",
                          color: theme.palette.primary.main,
                          fontWeight: "bold",
                        }}
                      >
                        1.
                      </Box>
                      <FormattedMessage
                        id="landing_recruiter.account.li_1"
                        values={{
                          b: (chunks) => <b>{chunks}</b>,
                        }}
                      />
                    </li>
                  </Typography>
                  <Typography variant="body1">
                    <li
                      style={{
                        display: "flex",
                        columnGap: isDesktop ? "4px" : "unset",
                      }}
                    >
                      <Box
                        variant="body1"
                        style={{
                          marginRight: "8px",
                          color: theme.palette.primary.main,
                          fontWeight: "bold",
                        }}
                      >
                        2.
                      </Box>
                      <Box>
                        <FormattedMessage
                          id="landing_recruiter.account.li_2"
                          values={{
                            b: (chunks) => <b>{chunks}</b>,
                          }}
                        />
                      </Box>
                    </li>
                  </Typography>
                  <Typography variant="body1">
                    <li
                      style={{
                        display: "flex",
                        columnGap: isDesktop ? "4px" : "unset",
                      }}
                    >
                      <Box
                        variant="body1"
                        style={{
                          marginRight: "8px",
                          color: theme.palette.primary.main,
                          fontWeight: "bold",
                        }}
                      >
                        3.
                      </Box>
                      <Box>
                        <FormattedMessage id="landing_recruiter.account.li_3" />
                        <b> +{spontaneousApplicationsYearCount} </b>
                        <FormattedMessage
                          id="landing_recruiter.account.li_3_after"
                          values={{
                            b: (chunks) => <b>{chunks}</b>,
                          }}
                        />
                      </Box>
                    </li>
                  </Typography>
                  <Typography variant="body1">
                    <li
                      style={{
                        display: "flex",
                        columnGap: isDesktop ? "4px" : "unset",
                      }}
                    >
                      <Box
                        variant="body1"
                        style={{
                          marginRight: "8px",
                          color: theme.palette.primary.main,
                          fontWeight: "bold",
                        }}
                      >
                        4.
                      </Box>
                      <FormattedMessage
                        id="landing_recruiter.account.li_4"
                        values={{
                          b: (chunks) => <b>{chunks}</b>,
                        }}
                      />
                    </li>
                  </Typography>
                  <Typography variant="body1">
                    <li style={{ display: "flex", columnGap: "4px" }}>
                      <Box
                        variant="body1"
                        style={{
                          marginRight: "8px",
                          color: theme.palette.primary.main,
                          fontWeight: "bold",
                        }}
                      >
                        5.
                      </Box>
                      <FormattedMessage id="landing_recruiter.account.li_5" />
                    </li>
                  </Typography>
                </ol>
                <Button
                  variant="rounded"
                  color="primaryOutlined"
                  onClick={() => {
                    setIsSignupModalOpened(true);
                    setSignUpModalContext("companyPage");
                  }}
                  style={{
                    width: isDesktop ? "275px" : "100%",
                    marginTop: "20px",
                  }}
                >
                  <FormattedMessage id="landing_recruiter.account.create_account" />
                </Button>
              </Grid>
            </Grid>
          </Container>
        </Box>
        <Box className={classes.pricing} id="pricing">
          <Container maxWidth="lg">
            <Grid
              container
              spacing={2}
              alignItems="center"
              display="flex"
              justifyContent="center"
            >
              <Grid item xs={12}>
                <Box
                  style={{
                    width: isDesktop ? "600px" : "100%",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                >
                  <Typography
                    variant="h1"
                    color="secondary"
                    style={{
                      textAlign: "center",
                      fontSize: isDesktop ? "48px" : "32px",
                      fontFamily: "Work Sans Bold",
                    }}
                  >
                    <FormattedMessage
                      id="landing_recruiter.prices.our_products"
                      values={{
                        b: (chunks) => <span>{chunks}</span>,
                      }}
                    />
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box
                  style={{
                    marginBottom: isDesktop ? "64px" : "24px",
                  }}
                >
                  <Typography
                    variant="body1"
                    color="secondary"
                    style={{
                      textAlign: "center",
                      fontSize: isDesktop ? "16px" : "14px",
                    }}
                  >
                    <FormattedMessage id="landing_recruiter.prices.our_products_subtitle" />
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <PricingDetails
                  onClickBusiness={() => {
                    setIsSignupModalOpened(true);
                    setSignUpModalContext("businessPurchase");
                  }}
                  onClickPremium={() => {
                    setIsSignupModalOpened(true);
                    setSignUpModalContext("premiumPurchase");
                  }}
                />
              </Grid>
            </Grid>
          </Container>
        </Box>
        <Box className={classes.download}>
          <Container maxWidth="lg">
            <Grid container spacing={2} alignItems="center" direction="column">
              <Grid
                item
                xs={12}
                md={6}
                style={{ display: "flex", justifyContent: "center" }}
              >
                <Box
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: isDesktop ? "200px" : "150px",
                  }}
                >
                  <Box
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="h2" style={{ textAlign: "center" }}>
                      <FormattedMessage
                        id="landing_recruiter.prices.download_text"
                        values={{
                          b: (chunks) => <b>{chunks}</b>,
                        }}
                      />
                    </Typography>
                    <Box mt={2}>
                      <Button
                        type="button"
                        variant="rounded"
                        color="secondaryOutlined"
                        onClick={() => setShowModal(true)}
                        style={{
                          width: isDesktop ? "200px" : "100%",
                          marginTop: "20px",
                        }}
                      >
                        <FormattedMessage id="landing_recruiter.prices.download_button" />
                      </Button>
                    </Box>
                  </Box>
                  <Modal
                    open={showModal}
                    onClose={() => setShowModal(false)}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <PricingModal closeModal={() => setShowModal(false)} />
                  </Modal>
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Box>
        <Box className={classes.carousel}>
          <Carousel
            NextIcon={<ArrowForwardIosIcon />}
            PrevIcon={<ArrowBackIosIcon />}
            navButtonsProps={{
              style: {
                backgroundColor: "transparent",
                opacity: isDesktop ? 1 : 0,
                marginRight: isDesktop ? "200px" : "0px",
                marginLeft: isDesktop ? "200px" : "0px",
              },
            }}
            animation={"slide"}
            duration={1}
            autoPlay={false}
          >
            {testimonials.map((testimonial) => (
              <CarouselItem carouselItem={testimonial} key={testimonial.key} />
            ))}
          </Carousel>
        </Box>
        <Box className={classes.partners}>
          <Box container maxWidth="lg">
            <Box item style={{ textAlign: "center" }}>
              <Typography
                variant="h1"
                style={{
                  color: theme.palette.secondary.main,
                  paddingTop: "20px",
                  paddingBottom: "50px",
                  fontWeight: "bold",
                  fontSize: isDesktop ? "36px" : "34px",
                }}
              >
                <FormattedMessage id="landing_recruiter.partners.header" />
              </Typography>
            </Box>
            <Box
              item
              style={{
                display: "flex",
                justifyContent: "center",
                gap: "50px",
                flexWrap: "wrap",
                paddingBottom: "115px",
              }}
            >
              <Box
                style={{
                  width: "150px",
                  height: "150px",
                  backgroundColor: "white",
                  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  src={Partner_1}
                  srcSet={`${Partner_1} 1x, ${Partner_1Small} 0.5x`}
                  alt="Partner 1"
                  style={{
                    display: "block",
                    width: "80px",
                    height: "80px",
                    margin: "auto",
                    objectFit: "contain",
                  }}
                />
              </Box>
              <Box
                style={{
                  width: "150px",
                  height: "150px",
                  backgroundColor: "white",
                  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  src={Partner_2}
                  srcSet={`${Partner_2} 1x, ${Partner_2Small} 0.5x`}
                  alt="Partner 2"
                  style={{
                    display: "block",
                    width: "130px",
                    height: "70px",
                    margin: "auto",
                    objectFit: "contain",
                  }}
                />
              </Box>
              <Box
                style={{
                  width: "150px",
                  height: "150px",
                  backgroundColor: "white",
                  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  src={Partner_3}
                  srcSet={`${Partner_3} 1x, ${Partner_3Small} 0.5x`}
                  alt="Partner 3"
                  style={{
                    display: "block",
                    width: "130px",
                    height: "70px",
                    margin: "auto",
                    objectFit: "contain",
                  }}
                />
              </Box>
              <Box
                style={{
                  width: "150px",
                  height: "150px",
                  backgroundColor: "white",
                  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  src={Partner_4}
                  srcSet={`${Partner_4} 1x, ${Partner_4Small} 0.5x`}
                  alt="Partner 4"
                  style={{
                    display: "block",
                    width: "120px",
                    height: "60px",
                    margin: "auto",
                    objectFit: "contain",
                  }}
                />
              </Box>
              <Box
                style={{
                  width: "150px",
                  height: "150px",
                  backgroundColor: "white",
                  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  objectFit: "contain",
                }}
              >
                <img
                  src={Partner_5}
                  srcSet={`${Partner_5} 1x, ${Partner_5Small} 0.5x`}
                  alt="Partner 5"
                  style={{
                    display: "block",
                    width: "150px",
                    height: "80px",
                    margin: "auto",
                    objectFit: "contain",
                  }}
                />
              </Box>
            </Box>
          </Box>
        </Box>
        <Box className={classes.contactForm}>
          <Container maxWidth="lg">
            <Grid container alignItems="center" spacing={2}>
              <Grid
                item
                xs={12}
                sm={7}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  rowGap: "40px",
                  paddingBottom: "35px",
                }}
              >
                <Box
                  style={{
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    variant="h2"
                    style={{
                      color: "white",
                      fontWeight: "600",
                      fontSize: isDesktop ? "42px" : "32px",
                    }}
                  >
                    <FormattedMessage
                      id="landing_recruiter.contact"
                      values={{
                        b: (chunks) => <b>{chunks}</b>,
                      }}
                    />
                  </Typography>
                </Box>
                <Box
                  style={{
                    maxWidth: "600px",
                  }}
                >
                  <Typography
                    variant="body1"
                    style={{
                      color: "white",
                      fontSize: "18px",
                      paddingRight: isDesktop ? "120px" : "0px",
                    }}
                  >
                    <FormattedMessage
                      id="landing_recruiter.contact_sub"
                      values={{
                        b: (chunks) => <b>{chunks}</b>,
                      }}
                    />
                  </Typography>
                </Box>
                {isDesktop && (
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      columnGap: "50px",
                    }}
                  >
                    <Box
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "left",
                      }}
                    >
                      <img
                        src={Jules}
                        srcSet={`${Jules} 1x, ${JulesSmall} 0.5x`}
                        alt="Jules 2"
                        className={classes.ellipseStyle}
                      />
                      <Typography
                        variant="subtitle1"
                        style={{
                          color: "white",
                          paddingTop: "10px",
                        }}
                      >
                        <b>
                          <FormattedMessage id="landing_recruiter.picture_jules" />
                        </b>
                      </Typography>
                      <Box
                        style={{
                          display: "flex",
                          columnGap: "8px",
                          color: "white",
                          paddingTop: "10px",
                        }}
                      >
                        <PhoneIcon />
                        <Link
                          target="_blank"
                          rel="noopener noreferrer"
                          underline="hover"
                          color="white"
                          href="tel:0032493483284"
                        >
                          <Typography>+32 (0) 493 48 32 84</Typography>
                        </Link>
                      </Box>
                      <Box
                        style={{
                          display: "flex",
                          columnGap: "8px",
                          color: "white",
                          paddingTop: "10px",
                        }}
                      >
                        <DateRangeIcon />
                        <Link
                          target="_blank"
                          rel="noopener noreferrer"
                          underline="hover"
                          color="white"
                          href="https://calendly.com/demo-squarehub/quick-demo-30-min"
                        >
                          <Typography>
                            <FormattedMessage id="landing_recruiter.contact_form.book_demo" />
                          </Typography>
                        </Link>
                      </Box>
                    </Box>
                    <Box
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "left",
                      }}
                    >
                      <img
                        src={Jade}
                        srcSet={`${Jade} 1x, ${JadeSmall} 0.5x`}
                        alt="Jade"
                        className={classes.ellipseStyle}
                      />
                      <Typography
                        variant="subtitle1"
                        style={{
                          color: "white",
                          paddingTop: "10px",
                        }}
                      >
                        <b>
                          <FormattedMessage id="landing_recruiter.picture_jade" />
                        </b>
                      </Typography>
                      <Box
                        style={{
                          display: "flex",
                          columnGap: "8px",
                          color: "white",
                          paddingTop: "10px",
                        }}
                      >
                        <PhoneIcon />
                        <Link
                          target="_blank"
                          rel="noopener noreferrer"
                          underline="hover"
                          color="white"
                          href="tel:003223291100"
                        >
                          <Typography>+32 (0) 2 329 11 00</Typography>
                        </Link>
                      </Box>
                    </Box>
                  </Box>
                )}
              </Grid>
              <Grid item xs={12} sm={5}>
                <Box
                  style={{
                    width: "100%",
                  }}
                >
                  <NewContactForm />
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Box>
      <Modal
        open={isSignupModalOpened}
        onClose={() => setIsSignupModalOpened(false)}
        aria-labelledby="modal-signup-title"
        aria-describedby="modal-signup-description"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <SignupModal
          context={signUpModalContext}
          counts={{
            candidatesCount: candidatesCount,
            jobApplicationsCount: jobApplicationsCount,
            candidatesSinceMonthCount: candidatesSinceMonthCount,
          }}
        />
      </Modal>
    </>
  );
};

export default LandingPageRecruiter;
